<template>

</template>

<script setup>

import Demo from "./js/Demo.js";

let g = (new Demo()).game;
g.draw();

</script>

<style scoped>

</style>

<template>
</template>

<script setup>
import Game from "./js/Game.js";

let g = new Game();
g.start();

</script>
